.messageContainer {
    position: fixed;
    bottom: 20px;
    left: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    z-index: 1000;
}

.message {
    background-color: #333;
    color: #fff;
    padding: 10px 20px;
    border-radius: 5px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    font-size: 14px;
    position: relative;
    animation: slideIn 0.3s ease-out, fadeOut 0.3s ease-in 4.7s forwards;
}

.message.error {
    background-color: #ff4d4d;
}

.message.success {
    background-color: #4caf50;
}

.closeButton {
    background: none;
    border: none;
    color: #fff;
    font-size: 16px;
    position: absolute;
    top: 5px;
    right: 0;
    cursor: pointer;
}

@keyframes slideIn {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes fadeOut {
    to {
        opacity: 0;
        transform: translateY(20px);
    }
}
