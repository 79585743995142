.container {
    font-family: 'Chakra Petch', monospace;
    width: 100%;
    height: 93vh;
    margin: 7vh 0 0 0;
    padding: 0;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background-color: #000000;
    color: #ffffff;
    overflow: hidden;
}

.chatBox {
    border: 1px solid #333333;
    border-radius: 0;
    height: 60vh;
    width: 90vw;
    padding: 20px;
    overflow-y: auto;
    margin-bottom: 20px;
    background-color: #0a0a0a;
    scrollbar-width: thin;
    scrollbar-color: #333333 #0a0a0a;
}

.chatBox::-webkit-scrollbar {
    width: 6px;
}

.chatBox::-webkit-scrollbar-track {
    background: #0a0a0a;
}

.chatBox::-webkit-scrollbar-thumb {
    background-color: #333333;
}

.userMessage,
.assistantMessage {
    margin: 15px 0;
    padding: 12px 16px;
    border-radius: 0;
    max-width: 80%;
    white-space: pre-wrap;
    line-height: 1.4;
    font-size: 14px;
}

.userMessage {
    align-self: flex-end;
    background-color: #ffffff;
    color: #000000;
    margin-left: auto;
    border-left: 4px solid #ffffff;
}

.assistantMessage {
    align-self: flex-start;
    background-color: #1a1a1a;
    color: #ffffff;
    border-left: 4px solid #ffffff;
    padding: 10px;
}

.messageContent {
    display: flex;
    align-items: flex-start;
    gap: 10px;
}

.messageContent img {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    margin-right: 10px;
}

.messageContent p {
    margin: 0;
    flex: 1;
}

.inputArea {
    display: flex;
    width: 100%;
    max-width: 1000px;
    justify-content: space-between;
    align-items: center;
    background-color: #0a0a0a;
    border: 1px solid #333333;
    padding: 10px;
}

.input {
    flex-grow: 1;
    padding: 12px;
    font-size: 14px;
    font-family: 'Chakra Petch', monospace;
    border: none;
    background-color: transparent;
    color: #ffffff;
    resize: none;
    min-height: 24px;
    max-height: 120px;
    overflow-y: auto;
}

.input:focus {
    outline: none;
}

.button {
    padding: 12px 24px;
    font-size: 14px;
    font-weight: 600;
    font-family: 'Chakra Petch', monospace;
    border-radius: 0;
    border: 1px solid #ffffff;
    background-color: #000000;
    color: #ffffff;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
}

.button:hover {
    background-color: #ffffff;
    color: #000000;
}

.polygonModalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.polygonModalContent {
    background: #0a0a0a;
    padding: 30px;
    border: 1px solid #ffffff;
    text-align: center;
    max-width: 400px;
    width: 90%;
}

.polygonButton {
    margin: 10px;
    padding: 12px 24px;
    border: 1px solid #ffffff;
    background-color: #000000;
    color: #ffffff;
    cursor: pointer;
    font-size: 14px;
    font-weight: 600;
    font-family: 'Space Mono', monospace;
    transition: background-color 0.3s ease, color 0.3s ease;
}

.polygonButton:hover {
    background-color: #ffffff;
    color: #000000;
}

.messageContent p em {
    opacity: 0.7;
    font-style: italic;
}