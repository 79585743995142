.container {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    min-height: 100vh;
    min-width: 100vw;
    height: fit-content;
    overflow: hidden;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 9999;
    opacity: 0.90;
    pointer-events: none;
}

.background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: -999;
    background: white;
    display: flex;
    align-items: center;
    justify-content: center;
}

.center {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    text-align: center;
}

.centerImage {
    width: 30%;
    max-width: 600px;
}

.centerText {
    margin-top: 20px;
    font-size: 2rem;
    font-weight: bold;
    color: black;
}
