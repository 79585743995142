.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 2rem;
  padding-right: 1rem;
  background-color: #fff;
  color: #000;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  border-bottom: 1px solid #ccc;
  height: 7vh;
  box-sizing: border-box;
}

.navbarContent {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navbarLinkTag {
  text-decoration: none;
  color: #000;
  display: flex;
  align-items: center;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  height: 100%;
  flex-direction: row;
}

.navbarLogo {
  display: flex;
  align-items: center;
  height: 100%;
  flex-direction: row;
  text-decoration: none;
  color: #000;
}

.logoText {
  font-size: 1.5rem;
  font-family: 'Chakra Petch', sans-serif;
  text-transform: uppercase;
  color: #000;
  font-weight: bold;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
}

.logoImage {
  height: 40px;
  margin-right: 1rem;
}

.navbarLinks {
  display: flex;
  align-items: center;
  gap: 3vw;
  text-decoration: none;
}

.navbarLinks a {
  text-decoration: none;
  color: #000;
  font-size: 1.2rem;
  font-family: 'Chakra Petch', sans-serif;
  text-transform: uppercase;
}

.navbarLinks a:hover {
  text-decoration: none;
}

.logoLink {
  margin-top: 0.5vh;
  width: auto;
  height: 2.5vh;
}

.profileContainer {
  display: flex;
  align-items: center;
  margin-right: 20px;
}

.profileImage {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 10px;
}

.walletAddress {
  font-size: 14px;
  color: #333;
}

.navBarLinkText {
  font-size: 1rem;
  font-family: 'Chakra Petch', sans-serif;
  text-transform: uppercase;
  color: #000;
  font-weight: bold;
}

.hamburger {
  display: none;
  cursor: pointer;
}

.hamburger img {
  width: 30px;
  height: 30px;
}

.dropdownMenu {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 7vh;
  right: 1rem;
  background-color: #fff;
  padding: 1rem;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.tooltip {
  position: absolute;
  left: 3vw;
  top: 50px; /* Adjust based on your layout */
  background-color: black;
  width: 10vw;
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  box-sizing: border-box;
  font-size: 0.75rem;
  z-index: 10;
  text-align: center;
  transform: translateX(-50%);
}

.tooltip::after {
  content: "";
  position: absolute;
  top: -10px;
  left: 50%;
  transform: translateX(-50%);
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent black transparent;
}


@media (max-width: 768px) {
  .navbarLinks {
    display: none;
  }

  .navbarIcons {
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  .navbarRight {
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  .hamburger {
    display: none;
  }

  .navbarContent {
    justify-content: space-between;
  }

  .navbarLinkTag {
  }

  .logoText {
    font-size: 1.2rem;
  }

  .logoImage {
    height: 30px;
  }

  .dropdownMenu {
    display: flex;
  }
}
