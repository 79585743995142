.container {
  display: flex;
  min-height: 100vh;
  width: 100vw;
  height: fit-content;
  overflow: hidden;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}

.homeBg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: -1;
}

.center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
}

.centerImage {
  margin-top: 5vh;
  width: 20vw;
  height: auto;
  margin-bottom: 1rem;
}

.centerText {
  font-size: 1.5rem;
  font-weight: 800;
  color: black;
  text-align: center;
}

.topleft {
  position: fixed;
  top: 5vh;
  left: 3vw;
  display: flex;
  align-items: center;
}

.topleftImage {
  height: 1.4rem;
  padding-bottom: .2rem;
}

.topleftText {
  font-size: 2rem;
  font-weight: 800;
  color: black;
}

.topCenter {
  position: fixed;
  top: 2.5vh;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
}

.topCenterText {
  font-size: 2rem;
  font-weight: 200;
  color: black;

}

.topRight {
  position: fixed;
  top: 5vh;
  right: 3vw;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  height: fit-content;
}

.topRightText {
  font-size: 1.25rem;
  color: black;
  margin-bottom: .75rem;
}

.topRightImage {
  height: 2rem;
  padding-bottom: .5rem;
}

.bottomLeft {
  position: fixed;
  bottom: 5vh;
  left: 3vw;
  font-size: 1.25rem;
  font-weight: 400;
  color: black;
}

.bottomRight {
  position: fixed;
  bottom: 5vh;
  right: 3vw;
}

.actionButton {
  margin-top: 65vh;
  align-items: center;
  appearance: none;
  background-color: #222;
  border-radius: 15px;
  border: black 3px solid;
  box-sizing: border-box;
  color: black;
  background: white;
  cursor: pointer;
  display: inline-flex;
  font-family: "Chakra Petch",monospace;
  height: 48px;
  justify-content: center;
  line-height: 1;
  list-style: none;
  overflow: hidden;
  position: relative;
  text-align: left;
  text-decoration: none;
  transition: box-shadow .15s,transform .15s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
  will-change: box-shadow,transform;
  font-size: 18px;
  font-weight: bold;
  width: 15vw;
}

.actionButton:active {
  background-color: rgba(255, 255, 255, 0.8);
  transform: scale(0.98);
}

.actionButtonImage {
  height: 1.5rem;
  margin-left: .5rem;
}

.sparkle {
  fill: white;
  transition: all 800ms ease;
}

.tooltipButton {
  position: absolute;
  bottom: 60px; /* Adjust based on your layout */
  right: 0;
  background-color: black;
  color: white;
  padding: 5px 10px;
  width: 100%;
  box-sizing: border-box;
  border-radius: 5px;
  font-size: 0.6rem;
  z-index: 10;
  text-align: center;
}

.tooltipButton::after {
  content: "";
  position: absolute;
  bottom: -10px; /* Adjust based on your layout */
  left: 50%;
  transform: translateX(-50%);
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent;
}

.tooltipLogo {
  position: absolute;
  bottom: 25vh; /* Adjust based on your layout */
  left: 50%;
  transform: translateX(-50%);
  background-color: black;
  color: white;
  padding: 5px 10px;
  width: 15vw;
  box-sizing: border-box;
  border-radius: 5px;
  font-size: 0.6rem;
  z-index: 10;
  text-align: center;
}

.tooltipLogo::after {
  content: "";
  position: absolute;
  top: -10px; /* Adjust based on your layout */
  left: 50%;
  transform: translateX(-50%);
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent black transparent;
}



@media (max-width: 768px) {
  .container {
    padding: 1rem;
    box-sizing: border-box;
    overflow: hidden;
    
  }

  .homeBg {
    width: 100%;
    height: 100%;
  }

  .center {
    width: 100vw;
    height: 100vh;
    justify-content: center;
  }

  .centerImage {
    width: 50%;
    height: auto;
    margin-top: 3vh;
  }

  .centerText {
    font-size: 1.25rem;
  }

  .topleft {
    top: 3vh;
    left: 5vw;
  }

  .topleftImage {
    height: 2rem;
  }

  .topleftText {
    font-size: 1.55;
    margin-bottom: .3rem;
  }

  .topRight {
    top: 3vh;
    right: 5vw;
  }

  .topRightImage {
    height: 2rem;
  }

  .bottomLeft {
    bottom: 3vh;
    left: 5vw;
    font-size: 1rem;
  }

  .bottomRight {
    bottom: 3vh;
    right: 5vw;
  }

  .actionButton {
    margin-top: 0;
    width: 30vw;
    font-size: 16px;
    color: white;
    background-color: black;
    box-shadow: rgba(45, 35, 66, 0.4) 0 2px 4px,rgba(45, 35, 66, 0.3) 0 7px 13px -3px,black 0 -3px 0 inset;
    font-weight: 200;
    height: 4.5vh;
  }
}
