.container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  min-height: 100vh;
  flex-direction: column;
}

html, body {
  margin: 0;
  padding: 0;
  font-family: 'Chakra Petch', sans-serif;
}