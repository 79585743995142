.container {
    display: flex;
    align-items: center;
    height: 93vh;
    justify-content: space-evenly;
    width: 100vw;
    flex-direction: column;
    font-family: "Chakra Petch", sans-serif;
    margin: 0;
    padding-top: 7vh;
    overflow: hidden;
    background: #282828;
}

.loading {
    width: 100vw;
    min-height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    cursor: wait;
    pointer-events: none;
}

.middleContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: row;
    text-align: left;
    overflow: hidden;
    position: relative;
    padding-left: 15vw;
    padding-bottom: 2vh;
    height: 100% - 10vh;
}

.colorInput {
    font-family: "Chakra Petch", sans-serif;
    font-weight: bolder;
    font-size: 1.5rem;
    border: none;
    background: none;
    outline: none;
    padding: 0 5px 0 5px;
    overflow: hidden;
    white-space: nowrap;
    color: rgba(0, 0, 0, 0.7);
    width: auto;
    /* Adjust width automatically */
    min-width: 80px;
    /* Minimum width */
    box-sizing: content-box;
    /* Ensure padding is not counted in width */
    caret-color: rgba(0, 0, 0, 0.7);
}

.colorInput::placeholder {
    font-weight: normal;
    color: rgba(0, 0, 0, 0.2);
    font-style: italic;
}

.middleLeftContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 50vw;
    height: fit-content;
    margin-top: 6vh;
}



.sentence {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    font-size: 1.5rem;
    margin-top: 5vh;
    font-weight: bold;
    color: black;
    width: 50vw;
    padding-bottom: 10vh;
    margin-left: 4vw;
    position: relative;
    /* Ensure relative positioning to allow child absolute positioning */
    z-index: 1;
    /* Ensure it's above other background elements */
}

.sentence div {
    display: flex;
    align-items: center;
    max-width: 50vw;
    box-sizing: content-box;
    padding-right: 2vw;
    padding-left: 2vw;
    justify-content: flex-start;
    position: relative;
    /* Ensure relative positioning to allow child absolute positioning */
}

.sentence span {
    margin-right: 0.5rem;
    margin-left: 0.5rem;
    min-width: fit-content;
    white-space: nowrap;
}

.sentenceChain {
    display: flex;
    align-items: flex-start;
    max-width: 50vw;
    box-sizing: content-box;
    padding-right: 2vw;
    justify-content: flex-start;
    position: relative;
    /* Ensure relative positioning to allow child absolute positioning */
    z-index: 1;
    /* Ensure it's above other background elements */
}

.sentenceChainSpan {
    margin-right: 0.5rem;
    margin-left: 0.5rem;
    min-width: fit-content;
    white-space: nowrap;
    position: absolute;
    top: 0;
    left: 2vw;
    z-index: 2;
    /* Higher z-index to ensure it overlays other elements */
}

.chainDropdownContainer {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 20vw;
}


.canvasContainer {
    height: fit-content;
    width: fit-content;
    margin-top: 5vh;
    display: flex;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    background: none;
    background: white;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
    position: relative;
}



.canvasInner {
    width: fit-content;
    height: fit-content;
    background: white;
    display: flex;
    align-items: center;
    justify-content: center;
}

.canvas {
    width: 40vh;
    height: 40vh;
    background: white;
    display: block;
}

.buttonsContainer {
    display: flex;
    justify-content: center;
    width: 50vw;
    align-items: center;
    flex-direction: column;
    gap: 3rem;
    height: 100%;
}

.generateButtonContainer {
    width: 18vw;
}

.mintButtonContainer {
    width: 35vw;
}

.actionButton {
    width: 100%;
    min-width: 18vw;
    align-items: center;
    appearance: none;
    background-color: #141414;
    border-radius: 15px;
    border-width: 0;
    box-sizing: border-box;
    color: white;
    cursor: pointer;
    display: inline-flex;
    font-family: "Chakra Petch", monospace;
    height: 65px;
    justify-content: center;
    line-height: 1;
    list-style: none;
    overflow: hidden;
    padding-left: 16px;
    padding-right: 16px;
    position: relative;
    text-align: left;
    text-decoration: none;
    transition: box-shadow .15s, transform .15s;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    white-space: nowrap;
    will-change: box-shadow, transform;
    font-size: 18px;
    font-weight: bold;
}

.actionButton:active {
    background-color: #111;
    transform: scale(0.98);
}

.bottomContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: fit-content;
    position: fixed;
    bottom: 0;
    left: 0;
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modalContent {
    background-color: #fff;
    padding: 20px;
    border-radius: 20px;
    text-align: center;
}

.blockedContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #1a1a1a; /* Dark background */
    color: #ffffff; /* White text */
    text-align: center;
    padding: 20px;
}

.blockedContainer h1 {
    font-size: 3rem;
    margin-bottom: 20px;
}

.blockedContainer p {
    font-size: 1.5rem;
}

.loadingContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f0f0f0;
}

@media (max-width: 1000px) {
    .actionButton {
        height: 65px;
    }
}

@media (max-width: 768px) {

    .homeBg {
        width: 100%;
    }

    .middleContainer {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100vw;
        height: 100%;
        padding: 0;
        margin: 0;
        overflow: visible;
    }

    .middleLeftContainer {
        width: 100%;
        padding: 0;
        margin: 0;
    }

    .sentence {
        width: 100vw;
        margin: 0;
        padding: 3vh 0 0 5vw;
        font-size: 1.2rem;
        display: flex;
        flex-wrap: wrap;
        /* Allow flex items to wrap */
        box-sizing: border-box;
    }

    .sentenceChain {
        width: 100vw;
        /* Adjust to full width */
        display: flex;
        margin-top: 0.5rem;
        /* Add margin for spacing */
        flex-direction: row;
        justify-content: center;
    }

    .chainDropdownContainer {
        position: absolute;
        left: 60%;
        transform: translateX(-50%);
        top: -1vh;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        width: 60vw;
        overflow: visible;
        height: 3vh;
        max-height: 3vh;
    }

    .colorInput {
        font-size: 1.2rem;
        flex-grow: 1;
        min-width: 100px;
        /* Allow the input to grow */
    }

    .colorInput::placeholder {
        font-size: 0.8rem;
        text-align: center;
    }

    .chainSelect {
        font-size: 1.2rem;
        position: relative;
        /* Ensure relative positioning */
        flex-grow: 1;
        /* Allow the select element to grow */
    }

    .canvasContainer {
        width: auto;
        height: 30vh;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 0;
    }

    .canvasInner {
        width: 100%;
        height: 100%;
        margin: 0;
        padding: 0;
    }

    .canvas {
        width: auto;
        height: 30vh;
        display: block;
    }

    .buttonsContainer {
        width: fit-content;
        flex-direction: row;
        gap: 2rem;
    }

    .generateButtonContainer {
        width: 30vw;
    }

    .mintButtonContainer {
        width: 30vw;
    }

    .actionButton {
        width: 30vw;
    }
}