.container {
    width: 100vw;
    min-height: 100vh;
    height: fit-content;
    box-sizing: border-box;
    padding: 10vh 0;
    margin: 0 auto;
    font-family: Arial, sans-serif;
    background-color: #141414;
    color: #fff;
    overflow: hidden;
  }
  
  .title {
    text-align: center;
    margin-bottom: 20px;
    color: #fff;
    font-size: 24px;
  }
  
  .mintList {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
    padding: 20px;
    box-sizing: border-box;
    max-width: 1600px;
    margin: 0 auto;
  }
  
  .mintCard {
    display: flex;
    border: 1px solid #3c3c3c;
    border-radius: 8px;
    background-color: #282828;
    transition: all 0.3s ease;
    overflow: hidden;
    box-sizing: border-box;
  }
  
  .newMint {
    animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both, glow 4s ease-in-out;
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
    perspective: 1000px;
  }
  
  @keyframes shake {
    10%, 90% { transform: translate3d(-1px, 0, 0); }
    20%, 80% { transform: translate3d(2px, 0, 0); }
    30%, 50%, 70% { transform: translate3d(-4px, 0, 0); }
    40%, 60% { transform: translate3d(4px, 0, 0); }
  }
  
  @keyframes glow {
    0%, 100% { box-shadow: 0 0 5px rgba(255, 255, 255, 0.5); }
    50% { box-shadow: 0 0 20px rgba(255, 255, 255, 0.8); }
  }
  
  .imageContainer {
    width: 100px;
    height: 100px;
    padding: 10px;
    flex-shrink: 0;
    box-sizing: content-box;
  }
  
  .tokenImage {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 4px;
  }
  
  .cardContent {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 15px;
    box-sizing: content-box;
  }
  
  .section {
    border-bottom: 1px solid #3c3c3c;
    padding: 10px 0;
    box-sizing: content-box;
  }
  
  .section:last-child {
    border-bottom: none;
  }
  
  .contractName {
    font-size: 16px;
    font-weight: bold;
    color: #ffffff;
    margin: 0 0 5px 0;
  }
  
  .contractAddress {
    font-size: 12px;
    color: #a0a0a0;
  }
  
  .stat {
    color: #a0a0a0;
    margin: 5px 0;
    font-size: 12px;
  }
  
  .timestamp {
    color: #6c6c6c;
    font-size: 11px;
  }
  
  .loading {
    text-align: center;
    color: #3c3c50;
    font-style: italic;
  }
  
  @keyframes colorChange {
    0%, 100% { color: #ffffff; }
    50% { color: #3c3c50; }
  }
  
  .newMint .contractName {
    animation: colorChange 4s ease-in-out;
  }
  
  @media (max-width: 1600px) {
    .mintList {
      grid-template-columns: repeat(3, 1fr);
    }
  }
  
  @media (max-width: 1200px) {
    .mintList {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  
  @media (max-width: 800px) {
    .mintList {
      grid-template-columns: 1fr;
    }
  }