/* ChainDropdown.module.css */

.dropdownContainer {
  position: relative;
  display: inline-block;
  width: 20vw;
  font-family: 'Chakra Petch', monospace;
  margin-left: 7vw;
}

.dropdownButton {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #fff;
  color: #333;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: 'Chakra Petch', monospace;
}

.arrow {
  margin-left: 10px;
  font-size: 12px;
}

.options {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  border: 1px solid #ccc;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  margin: 0;
  padding: 0;
  list-style: none;
  z-index: 1000;
}

.option {
  font-size: 1rem;
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.option:hover {
  background-color: #f0f0f0;
}

@media (max-width: 768px) {
  .dropdownContainer {
    width: 40vw;
  }

  .options {
    width: 100%;
  }
}
