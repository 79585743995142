/* General Container Styles */
.container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-family: "Chakra Petch", sans-serif;
  background-color: #141414;
  height: fit-content;
  min-height: 90vh;
  box-sizing: border-box;
  padding: 2rem; /* Added padding for overall container spacing */
}

.centerContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 80vw;
  background: #282828;
  border-radius: 20px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
  padding: 2rem;
  box-sizing: border-box;
}

.leftPanel {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%; /* Adjusted width to make it more balanced */
  position: relative;
}

/* NFT Card Styling to Match Profile */
.nftCard {
  background-color: #1a1a1a;
  border-radius: 10px;
  padding: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  box-sizing: border-box;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
  width: 100%; /* Adjusted to take full width of the left panel */
  max-width: 300px; /* Added max-width to prevent over-expansion */
  height: auto; /* Ensures card has a visible height */
  margin: 0 auto; /* Centers the NFT card within the left panel */
  position: relative; /* To allow transformations */
  isolation: isolate;
}

.nftCard::before {
  content: '';
  position: absolute;
  top: -3px;
  right: -3px;
  bottom: -3px;
  left: -3px;
  background: linear-gradient(
    45deg,
    #FFB3BA,  /* Pastel Pink */
    #FFDFBA,  /* Pastel Orange */
    #FFFFBA,  /* Pastel Yellow */
    #BAFFC9,  /* Pastel Green */
    #BAE1FF,  /* Pastel Blue */
    #E1BAFF   /* Pastel Purple */
  );
  border-radius: 13px;
  z-index: -1;
}

.nftCard::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #1a1a1a;
  border-radius: 10px;
  z-index: -1;
}

.nftCardImage {
  width: 100%;
  height: auto;
  border-radius: 8px;
  margin-bottom: 10px;
  object-fit: cover;
}

.nftDetails {
  width: 100%;
  text-align: left;
  color: white;
  padding: 10px; /* Adds padding inside the card for text */
}

.nftField {
  margin-bottom: 1vh;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.chainIcon {
  width: 16px;
  height: 16px;
  vertical-align: middle;
}

/* Right Panel Styling */
.rightPanel {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 50%; /* Adjusted width to make it more balanced */
  padding-left: 2rem;
  color: white;
}

.title {
  font-size: 2rem;
  font-weight: bolder;
  margin-bottom: 1rem;
  text-transform: uppercase;
  color: white;
}

.subtitle {
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 2rem;
  color: white;
  line-height: 1.5;
}

.callToAction {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-top: 1rem;
}

.actionButtonContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
}

.actionButton {
  display: flex;
  align-items: center;
  gap: 5px;
  background-color: #333;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 15px;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.actionButton:hover {
  background-color: #444;
}

.callToActionLogo {
  width: 40px;
  height: auto;
}

.telegramIcon {
  width: 30px;
  height: auto;
  color: white;
  cursor: pointer;
}

/* No NFTs Message */
.noNfts {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  color: #aaa;
  font-size: 1.2rem;
}

@media screen and (max-width: 768px) {
  .centerContainer {
    flex-direction: column;
    width: 90vw;
  }

  .leftPanel,
  .rightPanel {
    width: 100%;
    padding: 1rem;
  }

  .nftCard {
    max-width: 100%;
  }

  .nftDetails {
    padding: 5px;
  }

  .title {
    font-size: 1.5rem;
  }

  .subtitle {
    font-size: 0.9rem;
  }

  .actionButton {
    padding: 8px 15px;
    font-size: 0.75rem;
  }

  .callToActionLogo {
    width: 30px;
  }

  .telegramIcon {
    width: 25px;
  }
}