.container {
    display: flex;
    align-items: center;
    height: 93vh;
    justify-content: space-evenly;
    width: 100vw;
    flex-direction: column;
    font-family: "Chakra Petch", sans-serif;
    margin: 0;
    padding-top: 7vh;
    overflow: hidden;
}

.loading {
    width: 100vw;
    min-height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    cursor: wait;
    pointer-events: none;
}

.middleContainer {
    width: 100%;
    height: fit-content;
    max-height: calc(100% - (2 * (2 * 5vw + 2 * 0.35rem)) - 10vh);
    display: flex;
    justify-content: center;
    flex-direction: row;
    text-align: left;
    position: relative;
}

.colorInput {
    font-family: "Chakra Petch", sans-serif;
    font-weight: bolder;
    font-size: 1.5rem;
    border: none;
    background: none;
    outline: none;
    padding: 0 5px 0 5px;
    overflow: hidden;
    white-space: nowrap;
    color: rgba(0, 0, 0, 0.7);
    width: auto;
    /* Adjust width automatically */
    min-width: 80px;
    /* Minimum width */
    box-sizing: content-box;
    /* Ensure padding is not counted in width */
    caret-color: rgba(0, 0, 0, 0.7);
}

.colorInput::placeholder {
    font-weight: normal;
    color: rgba(0, 0, 0, 0.2);
    font-style: italic;
}

.colorInputGenerate {
    font-family: "Chakra Petch", sans-serif;
    font-weight: bolder;
    font-size: 1.5rem;
    border: none;
    background: none;
    word-wrap: break-word;
    outline: none;
    padding: 0 5px 10vh 5px;
    overflow: hidden;
    color: rgba(0, 0, 0, 0.7);
    width: auto;
    /* Adjust width automatically */
    min-width: 80px;
    /* Minimum width */
    box-sizing: content-box;
    /* Ensure padding is not counted in width */
    caret-color: rgba(0, 0, 0, 0.7);
    resize: none;
}

.colorInputGenerate::placeholder {
    font-weight: normal;
    color: rgba(0, 0, 0, 0.2);
    font-style: italic;
}

.middleLeftContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 50vw;
    height: 100%;
    padding-top: 6vh;
    position: relative;
}

.sentence {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    font-size: 1.5rem;
    margin-top: 5vh;
    font-weight: bold;
    color: black;
    width: 50vw;
    height: 100%;
    padding-bottom: 10vh;
    margin-left: 4vw;
    position: relative;
    /* Ensure relative positioning to allow child absolute positioning */
    z-index: 1;
    /* Ensure it's above other background elements */
}

.sentence div {
    display: flex;
    align-items: center;
    max-width: 50vw;
    box-sizing: content-box;
    padding-right: 2vw;
    padding-left: 2vw;
    justify-content: flex-start;
    position: relative;
    /* Ensure relative positioning to allow child absolute positioning */
}

.sentence span {
    margin-right: 0.5rem;
    margin-left: 0.5rem;
    min-width: fit-content;
    white-space: nowrap;
}

.sentenceChain {
    display: flex;
    align-items: flex-start;
    max-width: 50vw;
    box-sizing: content-box;
    padding-right: 2vw;
    justify-content: flex-start;
    position: relative;
    z-index: 1;
    font-family: 'Chakra Petch', monospace;
}


.sentenceChainSpan {
    margin-right: 0.5rem;
    margin-left: 0.5rem;
    min-width: fit-content;
    white-space: nowrap;
    position: absolute;
    top: 0;
    left: 2vw;
    z-index: 2;
    font-family: 'Chakra Petch', monospace;
    /* Higher z-index to ensure it overlays other elements */
}

.chainDropdownContainer {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 20vw;
    font-family: 'Chakra Petch', monospace;
    padding-left: 7vw;
}


.canvasContainer {
    height: 100%;
    margin-top: 2vh;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50vw;
    display: flex;
    flex-direction: column;
}

.canvasInner {
    width: auto;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
    height: 70%;
}

.canvas {
    width: 100%;
    height: 100%;

}

.buttonsContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: 50vw;
    align-items: center;
    height: 30%;
}

.freestyleButtonContainer {
    display: flex;
    justify-content: space-evenly;
    width: 50vw;
    align-items: center;
    height: 30%;
}

.buttonsBottomContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 50vw;
    align-items: center;
    height: 30%;
}

.generateButtonContainer {
    width: 10vw;
}

.uploadButtonContainer {
    width: 10vw;
}

.freestyleButton {
    background: white;
    color: black;
    position: relative;
    z-index: 0;
    padding: 10px 20px;
    /* Adjust padding as needed */
    border: none;
    /* Remove default border */
    border-radius: 5px;
    /* Adjust border-radius as needed */
    outline: none;
}

.freestyleButton::before {
    content: '';
    position: absolute;
    top: -3px;
    /* Adjust to control the thickness of the outline */
    bottom: -3px;
    /* Adjust to control the thickness of the outline */
    left: -3px;
    /* Adjust to control the thickness of the outline */
    right: -3px;
    /* Adjust to control the thickness of the outline */
    background: linear-gradient(to right, #FFB3BA, #FFDFBA, #FFFFBA, #BAFFC9, #BAE1FF);
    z-index: -1;
    border-radius: inherit;
    /* Ensures the border-radius is the same as the element */
}

.freestyleButton::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: white;
    border-radius: inherit;
    /* Ensures the border-radius is the same as the element */
    z-index: -2;
}


.actionButton {
    background-color: #3c3c3c;
    color: white;
}

.actionButton,
.freestyleButton {
    width: 10vw;
    align-items: center;
    appearance: none;
    border-radius: 15px;
    border-width: 0;
    box-sizing: border-box;
    cursor: pointer;
    display: inline-flex;
    font-family: "Chakra Petch", monospace;
    height: 4vh;
    justify-content: center;
    line-height: 1;
    list-style: none;
    overflow: hidden;
    padding-left: 10px;
    padding-right: 10px;
    position: relative;
    text-align: left;
    text-decoration: none;
    transition: box-shadow .15s, transform .15s;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    white-space: nowrap;
    will-change: box-shadow, transform;
    font-size: 12px;
    font-weight: bold;
}

.actionButton:active,
.freestyleButton:active {
    background-color: #282828;
    transform: scale(0.98);
}

.bottomContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: fit-content;
    position: fixed;
    bottom: 0;
    left: 0;
    background: none;
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modalContent {
    background-color: #fff;
    padding: 20px;
    border-radius: 20px;
    text-align: center;
}

.blockedContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #1a1a1a; /* Dark background */
    color: #ffffff; /* White text */
    text-align: center;
    padding: 20px;
}

.blockedContainer h1 {
    font-size: 3rem;
    margin-bottom: 20px;
}

.blockedContainer p {
    font-size: 1.5rem;
}

.loadingContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f0f0f0;
}

@media (max-width: 768px) {
    .container {
        min-height: 100vh;
        height: fit-content;
    }

    .middleContainer {
        margin: 0 5vh;
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        flex-direction: column;
        text-align: left;
        position: relative;
        height: 70vh;
    }

    .middleLeftContainer {
        width: 100%;
        height: 20vh;
        margin: 0;
        padding: 0;
    }

    .sentence {
        width: 100%;
        height: 35vh;
        margin: 0;
        padding: 0 5vw;
        box-sizing: border-box;
        font-size: 1rem;
    }

    .colorInput {
        font-size: 1rem;
        min-width: 150px;
    }

    .sentenceChain {
        width: 100%;
        height: 5vh;
        margin: 0;
        box-sizing: border-box;
        font-size: 1rem;
    }

    .chainDropdownContainer {
        width: 100%;
        height: 5vh;
        margin: 0;
        box-sizing: border-box;
        font-size: 1rem;
    }

    .canvasContainer {
        padding: 0;
        margin: 0;
        width: 100vw;
        height: 50vh;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }

    .canvasInner {
        width: 60vw;
        height: 60vw;
    }

    .canvas {
        width: 60vw;
        height: auto;
    }

    .buttonsContainer {
        width: 20vw;
        margin-left: 5vw;
        height: 100%;
        justify-content: center;
    }

    .freestyleButtonContainer {
        width: 20vw;
        height: 5vh;
        margin: 0;
    }

    .buttonsBottomContainer {
        width: 20vw;
        height: fit-content;
        flex-direction: column;
        padding: 0;
        margin: 0;
    }

    .generateButtonContainer, .uploadButtonContainer {
        width: 100%;
        height: 5vh;
        margin: 0;
        padding: 0;
    }

    .actionButton, .freestyleButton {
        width: 20vw;
        margin-bottom: 4vh;
    }

    .freestyleButton {
        font-size: 0.5rem;
    }
    
    .colorInputGenerate {
        padding: 0;
    }
}