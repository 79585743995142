.container {
  padding-top: 10vh;
  padding-bottom: 10vh;
  width: 100%;
  margin: 0 auto;
  min-height: 100vh;
  height: fit-content;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #282828;
}

.profileTop {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  border-radius: 20px;
  width: 95vw;
  height: 25vh;
  box-sizing: border-box;
  padding: 2vh 5vw;
  background: #141414;
}

.profilePicture {
  position: relative;
  margin-bottom: 20px;
  width: 100px;  /* Assuming the profile picture is 100x100 */
  height: 100px;
  isolation: isolate;
}

.profilePicture::before {
  content: '';
  position: absolute;
  top: -3px;
  right: -3px;
  bottom: -3px;
  left: -3px;
  background: linear-gradient(
    45deg,
    #FFB3BA,  /* Pastel Pink */
    #FFDFBA,  /* Pastel Orange */
    #FFFFBA,  /* Pastel Yellow */
    #BAFFC9,  /* Pastel Green */
    #BAE1FF,  /* Pastel Blue */
    #E1BAFF   /* Pastel Purple */
  );
  border-radius: 50%;
  z-index: -1;
}

.profileImage {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
}

.editIcon {
  position: absolute;
  bottom: 0;
  right: -10px;
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.profileInfo {
  width: 100%;
}

.profileName {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 5px;
  color: white;
}

.profileBio,
.profileAddress {
  font-size: 14px;
  color: #eee;
  margin-bottom: 5px;
}

.chainIcons {
  display: flex;
  gap: 10px;
  justify-content: center;
  margin-top: 10px;
}

.chainIcon {
  width: 50px;
  height: 50px;
}

.nftsContainer {
  width: 95vw;
  box-sizing: border-box;
  padding: 2vh 5vw 5vh 5vw;
  background: #141414;
  border-radius: 20px;
  margin-top: 20px;
}

.nftsToolbar {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
}

.nftsToolbarLeft {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 30vw;
  justify-content: space-between;
}

.collectionDropdownContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
  position: relative;
  width: 60%;
}

.collectionLabel {
  font-size: 16px;
  color: white;
  width: 10vw;
  margin-top: 7px;
}

.collectionDropdown {
  background-color: #f0f0f0;
  border-radius: 10px;
  padding: 10px 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  box-sizing: border-box;
}

.dropdownMenu {
  position: absolute;
  top: 100%;
  left: calc(50% + 10vw);
  transform: translateX(-50%);
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 10px;
  width: 20vw;
  box-sizing: border-box;
  overflow-y: auto;
  z-index: 10;
}


.dropdownItem {
  padding: 10px 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dropdownItem:hover {
  background-color: #f0f0f0;
}

.collectionImage {
  width: 20px;
  height: 20px;
  object-fit: cover;
}

.collectionTitle {
  flex-grow: 1;
  margin-left: 10px;
}

.nftCount {
  margin-left: 10px;
}

.viewToggle {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.viewToggle button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 5px;
  color: grey;
}

.viewToggle button.active {
  color: white;
}

.nftGrid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
}

.nftListWrapper {
  overflow-x: auto;
  width: 100%;
}

.nftList {
  width: 100%;
  border-collapse: collapse;
  min-width: 600px;
  color: white;
}

.nftList th,
.nftList td {
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #eee;
}

.nftImage {
  width: 50px;
  height: 50px;
  object-fit: cover;
}

.editPanel {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.editContent {
  background-color: white;
  padding: 20px;
  width: 90%;
  max-width: 400px;
  border-radius: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.editHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.closeButton {
  background: none;
  border: none;
  cursor: pointer;
  color: #666;
}

.formGroup {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.formGroup label {
  font-weight: bold;
  color: #333;
  margin-bottom: 5px;
}

.formGroup input,
.formGroup :global(.react-select__control) {
  padding: 8px 12px;
  border: 1px solid #ccc;
  border-radius: 8px;
  font-size: 14px;
}

.editButtons {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  margin-top: 20px;
}

.editButtons button {
  padding: 10px 20px;
  border: none;
  border-radius: 8px;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
  flex: 1;
}

.cancelButton {
  background-color: #f0f0f0;
  color: #333;
}

.cancelButton:hover {
  background-color: #e0e0e0;
}

.saveButton {
  background-color: #333;
  color: white;
}

.saveButton:hover {
  background-color: #666;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.modalContent {
  background-color: #fff;
  padding: 20px;
  border-radius: 20px;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 90%;
  max-width: 400px;
}

.loadingContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
}

.loadingAnimation {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.blintTitle {
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 20px;
  text-align: left;
  color: white;
  font-weight: 600;
}

.selectedOption {
  display: flex;
  align-items: center;
  gap: 10px;
}

.nftCard {
  position: relative;
  background-color: #1a1a1a;
  border-radius: 10px;
  padding: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
  isolation: isolate;
}

.nftCard::before {
  content: '';
  position: absolute;
  top: -3px;
  right: -3px;
  bottom: -3px;
  left: -3px;
  background: linear-gradient(
    45deg,
    #FFB3BA,  /* Pastel Pink */
    #FFDFBA,  /* Pastel Orange */
    #FFFFBA,  /* Pastel Yellow */
    #BAFFC9,  /* Pastel Green */
    #BAE1FF,  /* Pastel Blue */
    #E1BAFF   /* Pastel Purple */
  );
  border-radius: 13px;
  z-index: -1;
}

.nftCard::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #1a1a1a;
  border-radius: 10px;
  z-index: -1;
}

.nftCardImage {
  width: 100%;
  height: auto;
  border-radius: 8px;
  margin-bottom: 10px;
  object-fit: cover;
}

.nftDetails {
  width: 100%;
  text-align: left;
  position: relative;
}


.nftField {
  margin-bottom: 1vh;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}


.chainIcon {
  width: 16px;
  height: 16px;
  vertical-align: middle;
}

.cardChainIcon {
  width: 16px;
  height: 16px;
  vertical-align: middle;
}

.claimButton {
  background-color: #3c3c50;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 8px;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
  width: 100%;
  text-align: center;
  font-family: 'Chakra Petch', sans-serif;
}

.claimButton:hover {
  background-color: #28283c;
  transform: translateY(-2px);
}

.claimButton:active {
  background-color: #28283c;
  transform: translateY(0);
}

.claimButton:disabled {
  background-color: #ccc;
  cursor: not-allowed;
  color: #666;
}

.claimed {
  background-color: #282814;
  color: white;
}

.collectionDropdownContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
  position: relative;
  width: auto;
  gap: 10px;
}

.claimAllButtonContainer {
  position: relative;
  display: inline-block;
  width: 30%;
}

.claimAllButton {
  background-color: #3c3c50;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 8px;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
  font-family: 'Chakra Petch', sans-serif;
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 2vh;
}

.claimAllButton:hover {
  background-color: #28283c;
  transform: translateY(-2px);
}

.claimAllButton:active {
  background-color: #28283c;
  transform: translateY(0);
}

.claimAllButton:disabled {
  background-color: #ccc;
  cursor: not-allowed;
  color: #666;
}

.tooltip {
  position: absolute;
  background-color: #eee;
  color: black;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 0.8rem;
  z-index: 1000;
  bottom: -35px;
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.tooltip::after {
  content: "";
  position: absolute;
  top: -10px;
  left: 50%;
  transform: translateX(-50%);
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #eee transparent;
}

.tooltipClaimAll {
  position: absolute;
  background-color: #eee;
  color: black;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 0.8rem;
  z-index: 1000;
  bottom: -20px;
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.tooltipClaimAll::after {
  content: "";
  position: absolute;
  top: -10px;
  left: 50%;
  transform: translateX(-50%);
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #eee transparent;
}

.noBlintsFound {
  color: white;
  text-align: center;
  margin-top: 20px;
  font-size: 18px;
}

.blink {
  animation: blink-animation 1s steps(5, start) infinite;
}

@keyframes blink-animation {
  to {
    visibility: hidden;
  }
}


@media (min-width: 768px) {
  .container {
    padding: 10vh 10vw 5vh 10vw;
  }

  .profileTop {
    flex-direction: row;
    text-align: left;
    justify-content: space-between;
  }

  .profilePicture {
    margin-right: 20px;
    margin-bottom: 0;
  }

  .profileInfo {
    flex-grow: 1;
  }

  .chainIcons {
    justify-content: flex-end;
    margin-top: 0;
  }

  .collectionDropdownContainer {
    align-items: flex-start;
  }

  .collectionDropdown {
    width: 300px;
  }

  .dropdownMenu {
    left: 0;
    transform: none;
  }

  .viewToggle {
    justify-content: flex-end;
  }

  .nftGrid {
    grid-template-columns: repeat(auto-fill, minmax(275px, 1fr));
    gap: 20px;
  }

  .formGroup {
    flex-direction: row;
    align-items: center;
  }

  .formGroup label {
    width: 120px;
    margin-bottom: 0;
  }

  .formGroup input,
  .formGroup :global(.react-select__control) {
    flex-grow: 1;
  }
}

@media (max-width: 767px) {
  .container {
    padding-top: 10vh;
    padding-bottom: 5vh;
  }

  .profileTop {
    height: auto;
    padding: 2vh 3vw;
  }

  .profilePicture {
    width: 80px;
    height: 80px;
  }

  .profileImage {
    width: 80px;
    height: 80px;
  }

  .profileName {
    font-size: 20px;
  }

  .profileBio,
  .profileAddress {
    font-size: 12px;
  }

  .chainIcon {
    width: 30px;
    height: 30px;
  }

  .nftsContainer {
    padding: 2vh 3vw 3vh 3vw;
  }

  .nftsToolbar {
    flex-direction: column;
    align-items: flex-start;
  }

  .nftsToolbarLeft {
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
  }

  .collectionDropdownContainer {
    width: 100%;
    margin-bottom: 10px;
  }

  .collectionDropdown {
    width: 100%;
  }

  .dropdownMenu {
    width: 100%;
    left: 0;
    transform: none;
  }

  .viewToggle {
    width: 100%;
    justify-content: flex-start;
    margin-top: 10px;
  }

  .nftGrid {
    grid-template-columns: 1fr 1fr;
  }

  .nftCard {
    padding: 10px;
  }

  .nftField {
    font-size: 12px;
  }

  .claimButton {
    padding: 8px 12px;
    font-size: 12px;
  }

  .claimAllButtonContainer {
    width: 100%;
  }

  .claimAllButton {
    margin-bottom: 1vh;
  }

  .editContent {
    width: 95%;
    padding: 15px;
  }

  .formGroup label {
    margin-bottom: 5px;
  }

  .formGroup input,
  .formGroup :global(.react-select__control) {
    padding: 6px 10px;
    font-size: 12px;
  }

  .editButtons button {
    padding: 8px 16px;
    font-size: 12px;
  }

  .cardChainIcon {
    width: 10px;
    height: 10px;
    vertical-align: middle;
  }

  .editIcon {
    right: -15px;
  }
}