.container {
    min-height: 100vh;
    height: fit-content;
    width: 100vw;
    background-color: #141414;
    color: white;
    padding-top: 10vh;
    padding-bottom: 10vh;
    box-sizing: border-box;
    text-transform: uppercase;
    font-family: 'Chakra Petch', sans-serif;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.mainContainer {
    width: 75%;
    font-family: 'Chakra Petch', sans-serif;
}

.gridContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
}

.canvasContainer {
    width: 100%;
}

.canvasInner {
    position: relative;
    width: 100%;
    padding-bottom: 100%;
}

.canvas {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
}

@keyframes float {
    0%, 100% { transform: translateY(0); }
    50% { transform: translateY(-10px); }
  }
  
  .buttonWrapper {
    position: relative;
    margin: 1.5vh 0 0 0;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%; /* Ensure full width */
  }
  
  .floatingArrow {
    position: absolute;
    top: -40px;
    left: 50%;
    transform: translateX(-50%);
    animation: float 2s ease-in-out infinite;
    color: white;
    filter: drop-shadow(0 0 5px rgba(255, 255, 255, 0.7));
    pointer-events: none;
    z-index: 10;
  }
  
  .buttonsContainer {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  
.actionButton {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem 1rem;
    font-size: 1rem;
    font-weight: 500;
    color: white;
    background-color: #282814;
    border: 1px solid #3c3c50;
    border-radius: 0.375rem;
    cursor: pointer;
    transition: background-color 0.2s;
    font-family: 'Chakra Petch', sans-serif;
}

.actionButton:hover {
    background-color: #3c3c3c;
}

.actionButton:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.actionButton:first-child {
    flex-grow: 1;
}

.sparkle {
    padding-top: 5px;
    margin-right: 0.5rem;
    box-sizing: border-box;
}

.shareIcon {
    margin-right: 0.5rem;
}

.infoContainer {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.nftTitle {
    font-size: 1.875rem;
    font-weight: bold;
    margin-bottom: 1rem;
}

.mintContainer {
    background-color: #282828;
    border: 1px solid #3c3c3c;
    border-radius: 0.5rem;
    padding: 1rem;
    margin-bottom: 1rem;
    font-family: 'Chakra Petch', sans-serif;
}

.quantityContainer {
    margin-bottom: 1rem;
    width: 30%;
    box-sizing: border-box;
}

.quantityLabel {
    display: block;
    font-size: 1.125rem;
    font-weight: 600;
    margin-bottom: 0.5rem;
}

.quantityInput {
    width: 100%;
    padding: 0.5rem;
    font-size: 1rem;
    color: white;
    background-color: #3c3c3c;
    border: 1px solid #3c3c50;
    border-radius: 0.375rem;
}

.priceContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
    font-size: 1.125rem;
    font-weight: 600;
}

.mintButton {
    width: 100%;
    font-family: 'Chakra Petch', sans-serif;
    position: relative;
    z-index: 1;
}

.mintButton::before {
    content: '';
    position: absolute;
    top: -3px;
    right: -3px;
    bottom: -3px;
    left: -3px;
    background: linear-gradient(
        45deg,
        #FFB3BA,
        #FFDFBA,
        #FFFFBA,
        #BAFFC9,
        #BAE1FF,
        #D0BAFF
    );
    border-radius: 0.5rem;
    z-index: -1;
}

.mintButton::after {
    content: '';
    position: absolute;
    top: -2px;
    right: -2px;
    bottom: -2px;
    left: -2px;
    background: inherit;
    border-radius: 0.4375rem;
    z-index: -1;
}

.mintButton span {
    font-family: 'Chakra Petch', sans-serif;
}

.blormersContainer {
    background-color: #282828;
    border-radius: 0.5rem;
    padding: 1rem;
    margin-top: 1rem;
}

.blormersTitle {
    font-size: 1.25rem;
    font-weight: 600;
}

.blormersContent {
    flex-grow: 1;
    height: auto;
}

.statsContainer {
    background-color: #282828;
    border-radius: 0.5rem;
    padding: 1rem;
    margin-top: 1rem;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

.statsHeader {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
}

.statsIcon {
    margin-right: 0.5rem;
}

.statsTitle {
    font-size: 1.25rem;
    font-weight: 600;
}

.statsContent {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.statItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem;
    background-color: #1e1e1e;
    border-radius: 0.375rem;
}

.statLabel {
    font-size: 0.875rem;
    color: #a0a0a0;
}

.statValue {
    font-size: 1rem;
    font-weight: 600;
    color: #ffffff;
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modalContent {
    background-color: #282828;
    padding: 2rem;
    border-radius: 0.5rem;
    text-align: center;
}

@keyframes rainbow-glow {
    0% {
        background-position: 0% 50%;
    }

    100% {
        background-position: 200% 50%;
    }
}

.shinyButton {
    background: linear-gradient(90deg,
            #ffd1dc,
            #ffdac1,
            #ffffd1,
            #d1ffe5,
            #d1f3ff,
            #d5d1ff,
            #ffd1dc);
    background-size: 200% 100%;
    animation: rainbow-glow 3s linear infinite;
    color: #000;
    font-weight: bold;
    text-shadow: 0 0 5px rgba(255, 255, 255, 0.7);
    border: none;
    box-shadow: 0 0 10px rgba(255, 255, 255, 0.5);
    transition: all 0.3s ease;
}

.shinyButton:hover {
    box-shadow: 0 0 20px rgba(255, 255, 255, 0.7);
    transform: scale(1.05);
}

.blackSparkle {
    filter: brightness(0);
}

.blormersTable {
    width: 100%;
    border-collapse: collapse;
    margin-top: 1rem;
}

.blormersTable th,
.blormersTable td {
    border: 1px solid #3c3c3c;
    padding: 0.5rem;
    text-align: center;
}

.blormersTable th {
    background-color: #1e1e1e;
    color: #a0a0a0;
    font-weight: 600;
}

.blormersTable td {
    color: #ffffff;
    background-color: #282828;
}

.blormersTable tr:nth-child(even) {
    background-color: #333333;
}

.blockedContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #1a1a1a; /* Dark background */
    color: #ffffff; /* White text */
    text-align: center;
    padding: 20px;
}

.blockedContainer h1 {
    font-size: 3rem;
    margin-bottom: 20px;
}

.blockedContainer p {
    font-size: 1.5rem;
}

.loadingContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f0f0f0;
}

.titleContainer {
    display: flex;
    align-items: center;
    gap: 12px;
}

.xLink {
    display: flex;
    align-items: center;
    padding: 4px;
    border-radius: 4px;
    transition: all 0.2s ease;
}

.xLink:hover {
    transform: scale(1.1);
}

.xIcon {
    width: 20px;
    height: 20px;
    filter: invert(1); /* If your X icon is black and you want it white */
}

@media (max-width: 768px) {
    .gridContainer {
        grid-template-columns: 1fr;
    }

    .buttonsContainer {
        flex-direction: column;
    }

    .actionButton {
        width: 100%;
        margin-bottom: 1rem;
    }

    .actionButton:first-child {
        flex-grow: 0;
    }

    .floatingArrow {
        display: none;
    }
}