.container {
    width: 100%;
    min-height: 100vh;
    background-color: #141414;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 12.5vh;
    padding-bottom: 10vh;
    box-sizing: border-box;
}

.bannerWrapper {
    width: 90%;
    padding: 4px;
    border-radius: 12px;
    background: linear-gradient(
      45deg,
      #ffafbd,
      #ffc3a0,
      #ffdfba,
      #ffffba,
      #baffc9,
      #bae1ff,
      #d4bbff
    );
    background-size: 400% 400%;
    animation: gradientRotation 15s ease infinite;
  }
  
  @keyframes gradientRotation {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
  
  .banner {
    width: 100%;
    background-color: #282828;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    cursor: pointer;
    transition: background-color 0.2s;
    display: flex;
    flex-direction: row;
    height: 30vh;
    align-items: center;
    justify-content: space-between;
}

.bannerContent {
    padding: 3vh 2vw;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: auto;
}

.bannerImageWrapper {
    flex-shrink: 0;
    height: 100%;
    max-height: 30vh;
    width: auto;
    overflow: hidden;
}

.bannerImage {
    width: 100%;
    height: 100%;
    object-fit: cover;
}


.title {
    font-size: clamp(1rem, 2vh, 1.5rem); /* Minimum 1rem, scales with viewport, max 1.5rem */
    font-weight: bold;
    color: #e0e0e0;
}

.subtitle {
    font-size: clamp(0.75rem, 1.5vh, 1rem); /* Minimum 0.75rem, scales with viewport, max 1rem */
    color: #a0a0a0;
}

.stats {
    font-size: clamp(0.75rem, 1.5vh, 1rem); /* Minimum 0.75rem, scales with viewport, max 1rem */
    color: #a0a0a0;
}

.collectionsSection {
    width: 90%;
    margin-top: 32px;
}

.sectionHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
}

.sectionTitle {
    font-size: 24px;
    font-weight: bold;
    color: #e0e0e0;
}

.carousel {
    display: flex;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;
    scrollbar-width: none;
    -ms-overflow-style: none;
}

.carousel::-webkit-scrollbar {
    display: none;
}


.card {
    flex: 0 0 calc(20% - 16px);
    min-width: 200px;
    background-color: #282828;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    margin-right: 16px;
    scroll-snap-align: start;
    cursor: pointer;
    transition: background-color 0.2s;
}

.card:hover {
    background-color: #3c3c3c;
}

.cardImageWrapper {
    width: 100%;
    padding-top: 100%;
    /* This creates a 1:1 aspect ratio */
    position: relative;
}

.cardImage {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
}

.cardContent {
    padding: 12px;
}

.cardTitle {
    font-size: 14px;
    font-weight: 600;
    color: #e0e0e0;
    margin-bottom: 8px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.cardStat {
    font-size: 12px;
    color: #a0a0a0;
    margin-bottom: 4px;
}

.cardStatValue {
    font-size: 12px;
    font-weight: 500;
    color: #e0e0e0;
}

.table {
    width: 90%;
    margin-top: 48px;
    background-color: #282828;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
}

.table table {
    width: 100%;
    min-width: 600px;
    border-collapse: collapse;
}

.tableHeader {
    background-color: #3c3c3c;
}

.tableHeaderCell {
    padding: 12px 24px;
    text-align: left;
    font-size: 12px;
    font-weight: 500;
    color: #e0e0e0;
    text-transform: uppercase;
    cursor: pointer;
}

.tableBody {
    background-color: #282828;
}


.tableRow {
    border-bottom: 1px solid #3c3c3c;
    transition: background-color 0.2s;
    cursor: pointer;
}

.tableRow:hover {
    background-color: #3c3c50;
}


.tableCell {
    padding: 16px 24px;
    font-size: 14px;
    color: #e0e0e0;
}

.collectionCell {
    display: flex;
    align-items: center;
}

.collectionImage {
    width: 32px;
    height: 32px;
    border-radius: 4px;
    margin-right: 12px;
    object-fit: cover;
}

.button {
    font-family: 'Chakra Petch', sans-serif;
    height: 10vh;
    border-radius: 4px;
    font-weight: 500;
    transition: background-color 0.2s;
    cursor: pointer;
    border: none;
    outline: none;
}

.buttonDefault {
    background-color: #3c3c50;
    color: #e0e0e0;
}

.buttonOutline {
    background-color: transparent;
    border: 1px solid #3c3c50;
    color: #e0e0e0;
}

.buttonDefault:hover {
    background-color: #28283c;
}

.buttonOutline:hover {
    background-color: #3c3c50;
}

.buttonSm {
    height: 3.5vh;
    padding: .5vh 1vh;
    font-size: 12px;
}

.buttonDefault {
    height: 40px;
    padding: 8px 16px;
    font-size: 14px;
}

.buttonLg {
    height: 48px;
    padding: 12px 24px;
    font-size: 16px;
}

.button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.rainbowBanner {
    width: 100%;
    height: 30px;
    overflow: hidden;
    position: fixed;
    top: 7vh;
    background: linear-gradient(
        45deg,
        #ffafbd,
        #ffc3a0,
        #ffdfba,
        #ffffba,
        #baffc9,
        #bae1ff,
        #d4bbff
    );
    background-size: 400% 400%;
    animation: gradientMove 15s ease infinite;
    z-index: 999;
}

.rainbowContent {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: skew(-15deg);
}

.rainbowText {
    font-size: 12px;
    font-weight: bold;
    color: black;
    text-align: center;
    transform: skew(15deg);
}

@keyframes gradientMove {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}

@media screen and (max-width: 768px) {
    .container {
        padding-top: 15vh;
    }

    .banner {
        height: 35vh;
    }

    .bannerImageWrapper {
        height: 150px;
        width: 150px;
        margin-right: 5vw;
        border-radius: 8px;
    }

    .bannerContent {
        margin-left: 5vw;
    }

    .table {
        width: 100%; /* Full width on mobile */
        margin-top: 24px; /* Reduce top margin */
        border-radius: 0; /* Remove border radius */
    }

    .tableHeaderCell,
    .tableCell {
        padding: 12px 16px; /* Reduce padding */
    }

    .tableHeaderCell {
        white-space: nowrap; /* Prevent header text from wrapping */
    }

    .collectionCell {
        min-width: 150px; /* Ensure enough space for collection name */
    }

    .collectionImage {
        width: 24px; /* Reduce image size */
        height: 24px;
        margin-right: 8px;
    }
}