body, html {
    margin: 0;
    padding: 0;
    font-family: "Chakra Petch", sans-serif;
}

:root {
    --onboard-modal-z-index: 1001;
    --onboard-account-select-modal-z-index: 1001;
    --onboard-login-modal-z-index: 1001;
}