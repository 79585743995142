.authContainer {
  display: flex;
  align-items: center;
  text-decoration: none;
}

.authContainer:hover {
  text-decoration: none;
}

.profileImage {
  width: 4vh;
  height: 4vh;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 1vw;
  text-decoration: none;
}

.walletAddress {
  font-size: 1rem;
  color: black;
  margin-right: 10px;
  font-weight: 600;
  min-width: fit-content;
  text-decoration: none;
}

.walletAddress:hover {
  text-decoration: none;
}

.loginButton {
  text-decoration: none;
  align-items: center;
  appearance: none;
  background-color: #222;
  border-radius: 15px;
  border-width: 0;
  box-sizing: border-box;
  color: white;
  cursor: pointer;
  display: inline-flex;
  font-family: "Chakra Petch", monospace;
  height: 4vh;
  justify-content: center;
  line-height: 1;
  list-style: none;
  overflow: hidden;
  padding-left: 1vw;
  padding-right: 1vw;
  position: relative;
  text-align: left;
  text-decoration: none;
  transition: box-shadow .15s, transform .15s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
  will-change: box-shadow, transform;
  font-size: 1rem;
  font-weight: bold;
  width: 100%;
}

.loginButton:active {
  background-color: #111;
  transform: scale(0.98);
}

.signOutButton, .signOutButton:active, .signOutButton:focus, .signOutButton:hover {
  position: absolute;
  bottom: -.4vh;
  right: .3vw;
  width: .75vw;
  z-index: 10;
  text-decoration: none;
}


@media (max-width: 768px) {
  .authContainer {
    flex-direction: column;
    align-items: center;
    padding: 0;
  }

  .profileImage {
    width: 35px;
    height: 35px;
    margin-right: 5px;
  }

  .walletAddress {
    font-size: 0.7rem;
    margin-right: 0;
    text-align: center;
  }

  .loginButton {
    font-size: 0.7rem;
    padding-left: 2vw;
    padding-right: 2vw;
    width: auto;
    height: 5vh;
  }

  .signOutButton, .signOutButton:active, .signOutButton:focus, .signOutButton:hover  {
    right: -1.75vw;
    width: 3vw;
    z-index: 9999;
  }
}


